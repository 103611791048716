
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Poppins:wght@200&family=Roboto:wght@300&family=Exo+2:wght@300&display=swap');



body{
  font-family: "Fira Sans";
  overflow-x: hidden;
  background-color: rgb(220, 217, 217);
}

/* general css */
.cursor-pointer{
  cursor: pointer;
}

/* fonts */
.heading-font{
  font-family: 'Exo 2';
  font-weight: bolder;
}

/* navbar from flex css */

.searchform{
  display:flex;
}

.searchform button{
  border-radius:0 50% 50% 0;
  background:transparent;
  border:1px solid #5d5a5a;
}

.searchform select{
  border-radius: 0px;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}


.searchform input{
  border-radius:0px;
}

/* form search */


/* navbar part */

.navbar{
  background-color: #292929!important;
  color:#fff!important
}

.nav-link{
  color:#b4a8a8!important;
}


.border{
  border:2px solid #484141;
  box-shadow: 2px 2px 2px 2px #e3e3e3;
  /* background-color: #837e7e; */
}

 .navbar-brand{
  padding:0px;
  /* padding-left: 10px; */
}

.active,.nav-item{
  position: relative;
}


.navbar-nav .active::before{
  content: "";
  position: absolute;
  height: 2px;
  width:80%;
  bottom:0px;
  /* left:7px; */
  border:2px solid rgb(165, 35, 35);
}

.nav-item:before {
  position: absolute;
  content:"";
  /* height: 2px; */
  width: 0%;
  bottom: 0;
  left: 5px;
  border:2px solid rgba(165, 35, 35,0);
  
  /* display: none; */
}

.nav-item:hover:before{
  display: block;
  border:2px solid rgba(165, 35, 35,1);
  width: 90%;
  transition: 0.5s;
  /* animation: pulse; */
}

/* /nav part */


/* home */

.carousel-custom-icon{
  font-size: 2em;
  color: rgb(255, 255, 255);
  text-shadow: 2px 2px 10px #000000;

}

.carousel-control-next-icon,.carousel-control-prev-icon{
  /* background-color: rgba(0, 0, 0, 0.4); */
  padding: 10px;
  border-radius: 50%;
  color:#fff;
  text-shadow: 2px 2px 10px #000000;
  
}

/* .carousel-inner{
  border:2px solid rgb(170, 163, 163);
} */



.anchordiv a{
  text-decoration: none;
  color: #000;
  font-size: 15px;
}

/* @keyframes pulse {
  0% {
    width: 0%!important;
  }
  100% {
    width: 100%!important;
  }
} */

.submenu{
  position: absolute;
  width:500px;
  height:450px;
  border: 2px solid rgb(65, 63, 63);
  background-color: rgba(255, 255, 255, 0.99);
  color: #000;
  top:40px;
  opacity: 0;
  z-index: -999;
  visibility: hidden;
  margin-top: -50px;
  transition: all ease-in-out 0.3s;
}
.nav-item:hover .submenu{
  visibility: visible;
  z-index: 1;
  opacity: 1;
  margin-top: 0;
}

.submenu a{
  color: #000;
}

.vertical-center{
  display: flex;
  align-items: center;
  /* justify-content: center; */
}




.marqueedeatils{
  background-color: #000;
  color: #fff;
  margin-bottom: -7px;
}

/* for each cards */

.card{
  text-decoration: none;
}

.card-title{
  font-size: 16px;
}

.card:hover{
  transition: 0.3s;
  box-shadow: 17px 14px 15px 1px #828679;
  transform:scale(1.01)
}

.inunderline{
  position: relative;
  display: inline-block;
}

.inunderline:after{
  content: "";
  position: absolute;
  bottom: 0px;
  left:0px;
  height: 1px;
  width:100%;
  border:2px solid rgb(232, 21, 32);
}

.box{
  border: 2px solid rgb(155, 162, 166);
  position: relative;
  color: red;
  min-height: 200px;
  box-shadow: 5px 4px 6px -1px rgb(155, 162, 166);
}
.box a{
  color: #000;
  text-decoration:none;
  /* font-style: italic; */

}

.box:hover{
  box-shadow: 10px 9px 13px 5px rgb(155, 162, 166);
  transition: 0.2s;
  transform: scale(1.01);
}

.footer{
  min-height: 100px;
  /* background-image: url("https://i.imgur.com/vnWRRZS.jpeg"); */
  background-image: url("/public/footer3.jpg");
  /* background-attachment: fixed; */
  /* background-repeat: no-repeat; */
  background-size: contain;
  background-color: rgb(154, 120, 120);
}

.footer a{
  color:#fff
}


.locbox{
  position: absolute;
  left:50%;
  top:50%;
  width: 100%;
  transform: translate(-50%,-50%);
}

.col-6{
  border: 2px solid rgb(98, 68, 68);
}

.footer-content{
  background-color: rgba(0,0,0,0.7);
  width: 100%;
  height: 100%;
  color: #fff;
}


.carousel-indicators [data-bs-target] {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border:2px solid black;
  box-shadow: 0px 0px 1px 1px #000000;
}



.media-links{
  font-size: 30px;
  padding-right: 10px;
}



.form {
  z-index: 15;
  position: relative;
  background: #FFFFFF;
  /* width: 600px; */
  font-family: "Roboto", sans-serif;
  border-radius: 4px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin: 100px auto 10px;
  overflow: hidden;
}

@media screen and (min-width:1000px) {
  .product-description{
    max-height: 280px!important;
  }
  
}

@media only screen and (min-width: 600px) {
  .form{
    width:600px;
  }
  

}

@media screen and (max-width:600px) {
  .img-thumbnail{
    width: 170px!important;;
  }
  .submenu{
    display: none;
  }
  .navbar-nav .active::before{
    /* width:20%; */
    height: 30px;
    width:0px;
    left:-5px;
    top:6px;
  }
  .nav-item:hover:before{
    border: none;
  }
  .carousel-image{
    height:150px;
  }

  .nav-item{
    border-bottom: 1px solid rgb(161, 161, 169);
  }
  
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin: 0 0 20px;
}
.form-group:last-child {
  margin: 0;
}
.form-group label {
  display: block;
  margin: 0 0 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}

.form-group input {
  outline: none;
  display: block;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  border: 0;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 12px 20px;
  color: rgba(0, 0, 0, 0.6);
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  transition: 0.3s ease;
}
.form-group input:focus {
  color: rgba(0, 0, 0, 0.8);
}
.form-group button {
  outline: none;
  background: #4285F4;
  width: 100%;
  border: 0;
  border-radius: 4px;
  padding: 12px 20px;
  color: #FFFFFF;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
  line-height: inherit;
  text-transform: uppercase;
  cursor: pointer;
}


.form-group {
  color: #4285F4;
  font-size: 12px;
  text-decoration: none;
}
.form-panel {
  /* padding: 50px calc(5% + 60px) 60px 60px; */
  padding:20px;
  box-sizing: border-box;
}

.form-header {
  margin: 0 0 40px;
}
.form-header h1 {
  padding: 4px 0;
  color: #4285F4;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}





/* home brands image scroll effect */

.home-brand {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll; /* Enables horizontal scrolling */
  padding: 100px;
  border: 2px solid red;
}
.home-brand::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.home-brand::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.home-brand::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.home-brand::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.home-brand:not(:hover)::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}

.home-brand:not(:hover)::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.home-brands-logo {
  flex: 0 0 auto;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.brandLogoWrapper{
  min-height: 115px;
}

.cont {
  display: flex;
  /*flex-wrap: nowrap; /* Prevents wrapping of the child elements */
  overflow-x: scroll; /* Enables horizontal scrolling */
  padding: 10px;
}

.c1 {
  flex: 0 0 auto; /* Keeps the child elements at their default size */
  /* width: 50px; Sets the width of each child element */
  height: 80px;
  width: 80px;
  background-color: #f5f5f5;
  /* border: 2px solid #235ad0; */
  box-shadow: 2px 2px 7px -2px #3397b1;
  margin-right: 10px;
}

.radius50{
  border-radius: 50%;
}

.c1:hover{
  transform: scale(1.2);
  transition: all 0.3s ease-in-out;
  border:2px solid #eee;
}

.cont::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.cont::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.cont::-webkit-scrollbar-thumb {
  background-color: #837e7e;
}

.cont::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.cont:not(:hover)::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}

.cont:not(:hover)::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.list-brand-name{
  text-decoration: none;
  color:#000;
  transition: 0.1s;
}

.list-brand-name:hover{
  text-shadow: 1px 1px 2px #000;
}

.font-16{
  font-size:18px;
}


/* each brand page */


.brandLogo img{
  width: 200px;
  float:left;
  margin-right: 10px;

}

.clearfix{
  clear: both;
}



/* adminpanel css */
.prod-image-preview{
  height: 75px;
  width: 75px;
}

a{
  text-decoration: none;
}


.gallery-item img{
  height: 76px;
  border: 2px solid #eee;
}


.note{
  font-size: 12px;
}

.hover-underline:hover{
  text-decoration: underline;
}